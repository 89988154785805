<template>
  <v-container fluid>
    <MailsSectionSearch @search="() => $refs.table.refresh()" />

    <BaseTable
      ref="table"
      title="Elenco Templates"
      module="virtualAgentsMails"
      :headers="headers"
      :actions="actions"
      sortby="created_at"
    >
      <template v-slot:actions>
        <v-btn
          v-if="canUser('virtual_agents_mails', 'create')"
          color="green"
          dark
          tile
          @click="openModal"
        >
          Aggiungi
        </v-btn>
      </template>
    </BaseTable>
    <v-dialog
      v-model="dialog"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalClose"
    >
      <v-card>
        <v-card-title>Gestione Template</v-card-title>
        <v-card-text>
          <MailForm :title="null" :edit="editMode" @submitted="onSubmitted" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue'
import { mapActions, mapMutations } from 'vuex'
import { authComputed } from '@state/helpers.js'
import MailsSectionSearch from '@components/virtualagents/mail/MailsSectionSearch.vue'
import MailForm from '@components/virtualagents/mail/MailForm.vue'

export default {
  name: 'VirtualAgentMailsSearch',
  page: {
    title: 'Templates',
  },
  components: { BaseTable, MailForm, MailsSectionSearch },
  data() {
    return {
      dialog: false,
      editMode: false,
      headers: [
        {
          text: 'Nome Template',
          value: 'template',
        },
        {
          text: 'Soggetto',
          value: 'subject',
        },
        {
          text: 'Contenuto',
          value: 'content_subtitle',
        },
        {
          text: 'Data Creazione',
          value: 'created_at',
        },
        {
          text: 'Ultima Modifica',
          value: 'updated_at',
        },
      ],
      actions: [
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          onItemCondition: () => this.canUser('virtual_agents_mails', 'update'),
          handler: this.openModal,
        },
        {
          label: 'Elimina',
          icon: 'mdi-delete',
          color: 'red',
          onItemCondition: () => this.canUser('virtual_agents_mails', 'delete'),
          handler: this.remove,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
  mounted() {
    this.setScopes(['attachments'])
  },
  beforeDestroy() {
    this.setScopes([])
  },
  methods: {
    ...mapActions('virtualAgentsMails', ['removeItem']),
    ...mapMutations('virtualAgentsMails', {
      setCurrent: 'SET_CURRENT',
      setScopes: 'SET_SCOPES',
      resetCurrent: 'RESET_CURRENT',
      resetFilters: 'RESET_FILTER_FIELDS',
    }),
    async remove(item) {
      const res = await this.$dialog.confirm({
        text:
          "Proseguire con l'eliminazione del template ?\n l'azione non è reversibile!",
        title: 'Attenzione',
      })

      if (!res) return

      await this.removeItem(item.id)
      await this.$refs.table.refresh()
    },

    openModal(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },
    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
  },
}
</script>
