<template>
  <Form
    :hide-reset="edit"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <h4 class="mb-2">Dati</h4>
    <v-row>
      <FormItem v-model="template" rules="required" name="Nome Template" :lg="3" />
      <FormItem v-model="subject" rules="required" name="Soggetto" :lg="3" />
      <FormItem v-model="content_subtitle" name="Titolo Contenuto" :lg="3" />
    </v-row>  
    <v-row>
      <FormEditor 
        v-model="content_text" 
        rules="required" 
        name="Testo Template" 
        :cols="12"
        :sm="12"
        :md="12"
        :lg="10"
        :xl="9" 
      />
    </v-row>
    <v-row>
      <FormVAAttachments
        v-model="attachments"
        name="Allegati"
        hint="Allegati"
        multi
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'
import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormEditor from '@components/forms/FormEditor.vue'
import FormVAAttachments from '@components/forms/FormVAAttachments.vue'
import { authComputed } from '@state/helpers.js'

const { mapFields } = createHelpers({ 
  getterType: 'virtualAgentsMails/getCurrent',
  mutationType: 'virtualAgentsMails/SET_CURRENT_FIELDS',
})

export default {
  name: 'MailForm',
  components: {
    FormVAAttachments,
    FormEditor,
    FormItem,
    Form,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo Template',
    },
  },
  computed: {
    ...authComputed,
    ...mapFields([
      'template',
      'subject',
      'content_subtitle',
      'content_text',
      'attachments'
    ]),
    ...mapState('virtualAgentsMails', {
      id: (state) => state.current.id,
      types: (state) => state.types,
      loading: (state) => state.loading,
    }),
  },
  methods: {
    ...mapMutations('virtualAgentsMails', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('virtualAgentsMails', ['create', 'update']),
    async onSubmit() {
      this.$emit('submit')

      if (!this.edit) {
        await this.create()
      } else {
        await this.update()
      }

      this.$emit('submitted', this.id)
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
