<template>
  <FormItem :clearable="clearable" 
  :dense="dense" 
  :disabled="disabled" 
  :hint="hint" 
  :label="label || name" :name="name"
  :persistent-hint="persistentHint" 
  :rules="rules" 
  :type="multi ? 'autocomplete' : 'select'" :value="value"
  :values="list" 
  :cols="cols" 
  :sm="sm" 
  :md="md" 
  :lg="lg" 
  :xl="xl" 
  @input="onInput" />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FormItem from '@components/forms/FormItem.vue'
import { debounce } from 'lodash'

export default {
  name: 'FormVAAttachments',
  components: { FormItem },
  props: {
    clearable: {
      default: false,
      type: Boolean,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    cols: {
      default: 12,
      type: [String, Number],
    },
    sm: {
      default: 6,
      type: [String, Number],
    },
    md: {
      default: 3,
      type: [String, Number],
    },
    lg: {
      default: 3,
      type: [String, Number],
    },
    xl: {
      default: 3,
      type: [String, Number],
    },
    multi: {
      default: false,
      type: Boolean,
    },
    name: {
      default: 'Allegato',
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    rules: {
      default: null,
      required: false,
      type: [String, Object],
    },
    value: {
      default: null,
      type: [String, Array],
    },
  },
  computed: {
    ...mapState('virtualAgentsAttachments', ['list']),
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions('virtualAgentsAttachments', {
      getData: 'getDropdownList',
    }),
    onInput: debounce(function (value) {
      this.$emit('input', value)
    }, 250),
  },
}
</script>
