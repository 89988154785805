<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <span class="text-body-2">{{ name }}</span>
    <TiptapVuetify
      v-model="content"
      :extensions="extensions"
      :toolbar-attributes="{ color: 'bg_section' }"
      :card-props="{ flat: true, filled: true, height: 350 }"
    />
  </v-col>
</template>

<script>
import { debounce } from 'lodash'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from 'tiptap-vuetify'

export default {
  name: 'FormEditor',
  components: { TiptapVuetify },
  props: {
    cols: {
      type: Number,
      default: 12,
    },
    sm: {
      type: Number,
      default: null,
    },
    md: {
      type: Number,
      default: 6,
    },
    lg: {
      type: Number,
      default: null,
    },
    xl: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    value: {
      default: null,
      required: true,
      type: [String, Object],
    },
  },
  data: () => ({
    content: null,
    extensions: [
      History,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Paragraph,
      Bold,
      Italic,
      Underline,
      Strike,
      ListItem,
      BulletList,
      OrderedList,
      Link,
      Blockquote,
      Code,
      HorizontalRule,

      HardBreak,
    ],
  }),
  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.content = v
      },
    },
    content(value) {
      this.onInput(value)
    },
  },
  methods: {
    onInput: debounce(function(val) {
      this.$emit('input', val)
    }, 250),
  },
}
</script>

<style>
.tiptap-vuetify-editor__content {
  max-height: 300px;
}
</style>
