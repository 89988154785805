<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="template"
        type="text"
        name="Nome"
        dense
        :sm="6"
        :md="3"
        :lg="3"
        :xl="3"
      />

      <FormItem
        v-model="subject"
        type="text"
        name="Oggetto"
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="content_subtitle"
        type="text"
        name="Sottotitolo"
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
      <FormVAAttachments
        v-model="attachments"
        name="Allegati Abbinati"
        multi
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapMutations, mapState } from 'vuex'

import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormVAAttachments from '@components/forms/FormVAAttachments.vue'
import FormItem from '@components/forms/FormItem.vue'

const { mapFields } = createHelpers({
  getterType: 'virtualAgentsMails/getFiltersFields',
  mutationType: 'virtualAgentsMails/SET_FILTER_FIELDS',
})

export default {
  name: 'MailsSectionSearch',
  components: { FormItem, BaseSectionSearch, FormVAAttachments },
  computed: {
    ...mapFields(['template', 'subject', 'content_subtitle', 'attachments']),
    ...mapState('virtualAgentsMails', ['filters', 'types']),
  },
  mounted() {
    this.reset()
  },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapMutations('virtualAgentsMails', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>

<style scoped></style>
